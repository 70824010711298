<template>
  <v-container id="Experiencias" class="mx-auto">
    <v-row v-if="experiencias.length === 0">
      <v-col v-for="n of 6" :key="n" cols="12" sm="6" md="4">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <div v-for="(zona, i) in zonas" :key="i" v-else>
      <h1 class="my-5 pl-5 titulos">{{ zona }}</h1>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="experiencia in ordenadasPorZonas[zona]"
          :key="experiencia.id"
        >
          <div class="mx-auto">
            <router-link
              :to="{
                name: 'Detalle',
                params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
              }"
            >
              <CardExperiencias :infoExperiencia="experiencia" />
            </router-link>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import CardExperiencias from "@/components/Cards/CardExperiencias";
import { mapGetters, mapState } from "vuex";
export default {
  name: "Ordenadas por zonas",
  components: {
    CardExperiencias,
  },

  data() {
    return {
      zonas: [],
    };
  },

  computed: {
    ...mapState("Experiencias", ["experiencias"]),
    ordenadasPorZonas() {
      const { experiencias } = this;
      const experienciasOrdenadasPorZonas = {};
      experiencias.forEach((e) => {
        const { data: experiencia } = e;
        const { zona } = experiencia;
        if (!experienciasOrdenadasPorZonas[zona])
          experienciasOrdenadasPorZonas[zona] = [];

        experienciasOrdenadasPorZonas[zona].push(e);
      });

      const zonas = Object.keys(experienciasOrdenadasPorZonas);
      this.zonas = zonas;
      return experienciasOrdenadasPorZonas;
    },
  },
};
</script>

<style lang="scss" scoped>
.titulos {
  font-size: 20px;
}

@media (min-width: 960px) {
  .titulos {
    font-size: 32px;
  }
}
</style>
