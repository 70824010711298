<template>
  <v-container id="Experiencias" class="mx-auto">
    <div class="row">
      <div class="col-12 col-sm-3 ">
        <h1>
          {{ lenguages[idioma].buscador.titulo }}
          ({{ experienciasFiltradas.length }})
        </h1>

        <v-row>
          <v-col cols="12">
            <v-text-field
              dense
              :items="aeronaves"
              :label="lenguages[idioma].buscador.destino"
              outlined
              v-model="filtro.destino"
              class="col-3"
              color="#645dc7"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              dense
              :items="aeronaves"
              :label="lenguages[idioma].buscador.aeronave"
              outlined
              v-model="filtro.aeronave"
              class="col-3"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              dense
              :items="precios"
              :label="lenguages[idioma].buscador.precio"
              outlined
              v-model="filtro.precio"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              dense
              :items="zonas"
              :label="lenguages[idioma].buscador.zona"
              outlined
              v-model="filtro.zona"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              dense
              :items="servicios"
              :label="lenguages[idioma].buscador.servicio"
              outlined
              v-model="filtro.servicio"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              dense
              :items="tiempos"
              :label="lenguages[idioma].buscador.tiempo"
              outlined
              v-model="filtro.tiempo"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              dense
              :items="asientos"
              :label="lenguages[idioma].buscador.asientos"
              outlined
              v-model="filtro.asientos"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <!-- <p class="my-4">{{ lenguages[idioma].buscador.ordenadoPor }}:</p> -->
          <v-col cols="12">
            <v-checkbox
              dense
              v-model="filtro.experienciasItau"
              :label="lenguages[idioma].buscador.experienciasITAU"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="fechas">
            <p>Por rango de fechas:</p>
            <v-menu v-model="fechaInicialShow" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filtro.fechaInicial"
                  label="Fecha inicial"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="#645dc7"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filtro.fechaInicial"
                color="#645dc7"
                @input="fechaInicialShow = false"
                :allowed-dates="
                  (date) =>
                    filtro.fechaFinal
                      ? new Date(date) <= new Date(filtro.fechaFinal)
                      : true
                "
              ></v-date-picker>
            </v-menu>
            <v-menu v-model="fechaFinalShow" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filtro.fechaFinal"
                  label="Fecha final"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="#645dc7"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filtro.fechaFinal"
                color="#645dc7"
                @input="fechaFinalShow = false"
                :allowed-dates="
                  (date) =>
                    filtro.fechaInicial
                      ? new Date(date) >= new Date(filtro.fechaInicial)
                      : true
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>

      <div class="col-12 col-sm-9 ">
        <div v-show="experienciasFiltradas.length == 0">
          <h2>
            {{ lenguages[idioma].buscador.loSentimos }}
          </h2>
        </div>
        <v-row v-if="experiencias.length === 0">
          <v-col v-for="n of 6" :key="n" cols="12" sm="6" md="4">
            <v-skeleton-loader
              class="mx-auto"
              max-width="300"
              type="card"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
            v-for="experiencia in experienciasFiltradas"
            :key="experiencia.id"
          >
            <div class="mx-auto">
              <router-link
                :to="{
                  name: 'Detalle',
                  params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
                }"
              >
                <CardExperiencias :infoExperiencia="experiencia" />
              </router-link>
            </div>
          </v-col>
        </v-row>
        <OrdenadasPorZonas v-show="filtro.zonas" />
      </div>
    </div>
  </v-container>
</template>

<script>
import CardExperiencias from "@/components/Cards/CardExperiencias";
import OrdenadasPorZonas from "@/components/Experiencias/OrdenadasPorZonas";
import { mapGetters, mapState } from "vuex";
export default {
  name: "Experiencias",
  components: {
    CardExperiencias,
    OrdenadasPorZonas,
  },
  data() {
    return {
      filtro: {
        servicio: "",
        zona: "",
        aeronave: "",
        precio: "",
        tiempo: "",
        asientos: "",
        experienciasItau: false,
        fechaInicial: "",
        fechaFinal: "",
      },
      fechaFinalShow: false,
      fechaInicialShow: false,
      aeronaves: [
        {
          text: "Helicóptero",
          value: "helicoptero",
        },
        {
          text: "Avión",
          value: "avion",
        },
        {
          text: "Cualquier aeronave",
          value: "",
        },
      ],
      zonas: [
        {
          text: "Norte",
          value: "Norte",
        },
        {
          text: "Centro",
          value: "Centro",
        },
        {
          text: "Sur",
          value: "Sur",
        },
        {
          text: "Cualquiera",
          value: "",
        },
      ],
      precios: [
        { text: "Económico", value: "economico" },
        { text: "Premium", value: "premium" },
        {
          text: "Cualquier precio",
          value: "",
        },
      ],
      servicios: [
        { text: "Privado", value: "privado" },
        { text: "Compartido", value: "compartido" },
        { text: "Cualquiera", value: "" },
      ],

      tiempos: [
        { text: "Hasta 15 minutos", value: "15" },
        { text: "Hasta 30 minutos", value: "30" },
        { text: "Hasta 45 minutos", value: "45" },
        { text: "Hasta 60 minutos", value: "60" },
        { text: "Hasta 90 minutos", value: "60" },
        { text: "Cualquiera", value: "" },
      ],
      asientos: [
        { text: "Hasta 2 asientos", value: "2" },
        { text: "Hasta 4 asientos", value: "4" },
        { text: "Hasta 6 asientos", value: "6" },
        { text: "Hasta 8 asientos", value: "8" },
        { text: "Hasta 10 asientos", value: "10" },
        { text: "Cualquiera", value: "" },
      ],
    };
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapGetters("Experiencias", ["getExperienciasAMostrar"]),
    experiencias() {
      return this.getExperienciasAMostrar;
    },
    experienciasFiltradas() {
      const {
        destino,
        aeronave,
        precio: precioFiltro,
        experienciasItau,
        zona,
        servicio,
        tiempo,
        asientos,
        fechaInicial,
        fechaFinal,
      } = this.filtro;

      let { experiencias } = this;

      if (destino) {
        experiencias = experiencias.filter((e) =>
          e.data.destino.toLowerCase().includes(destino.toLowerCase())
        );
      }

      if (aeronave) {
        experiencias = experiencias.filter((e) =>
          e.data.aeronave.includes(aeronave)
        );
      }

      if (precioFiltro) {
        experiencias = experiencias.filter(({ data: { precio } }) =>
          precioFiltro == "economico"
            ? precio < 300000
            : precioFiltro == "premium"
            ? precio > 300000
            : true
        );
      }

      if (experienciasItau) {
        experiencias = experiencias.filter(
          ({ data: { itauExperiencia } }) => itauExperiencia
        );
      }

      if (zona)
        experiencias = experiencias.filter(
          ({ data: { zona: expZona } }) => expZona.split(" ")[0] === zona
        );

      if (servicio)
        if (servicio == "privado")
          experiencias = experiencias.filter(
            ({ data: { privado } }) => privado
          );
        else if (servicio == "compartido")
          experiencias = experiencias.filter(
            ({ data: { privado } }) => !privado
          );

      if (tiempo)
        experiencias = experiencias.filter(
          ({ data: { tiempoDeVuelo } }) => +tiempoDeVuelo <= +tiempo
        );

      if (asientos)
        experiencias = experiencias.filter(
          ({ data: { capacidad } }) => +capacidad <= +asientos
        );

      if (fechaInicial) {
        experiencias = experiencias.filter(({ data: { fechas } }) => {
          if (fechas) {
            const existeAlgunaFechaFuturaDeEstaExperiencia = fechas.some(
              (d) =>
                new Date(d).getTime() >=
                new Date(fechaInicial + "GMT-3").getTime()
            );
            if (existeAlgunaFechaFuturaDeEstaExperiencia) return true;
            else return false;
          } else return false;
        });
      }

      if (fechaFinal) {
        experiencias = experiencias.filter(({ data: { fechas } }) => {
          if (fechas) {
            const fechasAnteriorDeEstaExperiencia = fechas.filter(
              (d) =>
                new Date(d).getTime() <=
                new Date(fechaFinal + "GMT-3").getTime()
            );
            console.log(fechasAnteriorDeEstaExperiencia, fechas);
            if (fechasAnteriorDeEstaExperiencia.length) {
              console.log(fechasAnteriorDeEstaExperiencia);
              return true;
            } else return false;
          } else return false;
        });
      }

      return experiencias;
    },
  },
  title() {
    return `Experiencias - HummingBird`;
  },

  mounted() {
    const { query } = this.$route;

    for (let [property, value] of Object.entries(query))
      this.filtro[property] = value;
  },

  watch: {
    filtro: {
      handler(filtro) {
        const queryStrings = { ...this.$route.query };
        for (let [property, value] of Object.entries(filtro))
          if (value) {
            queryStrings[property] = value;
          } else {
            delete queryStrings[property];
          }

        this.$router.push({ query: { ...queryStrings } }).catch(() => {});
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#Experiencias {
  padding-top: 150px;
  margin-bottom: 85px;
}

@media (min-width: 960px) {
  #Experiencias {
    h1 {
      font-size: 32px;
    }
    h3 {
      font-size: 20px;
    }
  }
}

.filtros {
  display: flex;
  justify-content: center;
}

.fechas {
  border-top: ridge 1px rgba(128, 128, 128, 0.402);
  p {
    margin: 10px 0px;
  }
}
</style>
